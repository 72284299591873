import React from "react";

import { Table } from "react-bootstrap";

import { buildDisplayItems } from "../../../services/display_item_helper";

import { InputContext } from "../../../contexts";

const HorizontalSubformBody = ({
  subform,
  buildDeleteButton,
  buildNewButton,
  buildReorderButtons,
  displayItem,
  readonly,
  buildHiddenFields,
  buildRowContext,
}) => {
  const tableHeader = () => {
    const headers = displayItem.headers.map((header, index) => {
      return (
        <th key={index} className="min-width-200">
          {header}
        </th>
      );
    });

    return (
      <>
        {/* Extra column for row number */}
        <th></th>

        {headers}

        {/* Extra column for action buttons (if on new/edit) */}
        {readonly ? null : <th></th>}
      </>
    );
  };

  const colSpan = readonly
    ? displayItem.headers.length + 1
    : displayItem.headers.length + 2;

  let row_num = 0;

  const rows = subform.subformData.map((row, index) => {
    const rowContent = (
      <>
        <tr
          id={`${subform.pluralizedIdentifier}_${index}`}
          className={row.marked_for_delete ? "table-danger" : null}
        >
          <td
            rowSpan={row.base_errors.length > 0 ? 2 : 1}
            className="align-middle"
          >
            {index + 1}
          </td>

          {buildDisplayItems(displayItem.row)}

          {/* Final Column For Delete action */}
          {readonly ? null : (
            <td className="text-nowrap px-1 text-center">
              {buildHiddenFields(row, index, row_num++)}

              {buildDeleteButton(row, index)}

              {buildReorderButtons(index)}
            </td>
          )}
        </tr>
        {row.base_errors.length > 0 && (
          <tr
            id={`${subform.pluralizedIdentifier}_${index}_errors`}
            className={row.marked_for_delete ? "table-danger" : null}
          >
            <td colSpan={colSpan - 1} className="text-danger text-center">
              {row.base_errors.map((error, index) => {
                return <div key={index}>{error}</div>;
              })}
            </td>
          </tr>
        )}
      </>
    );

    return buildRowContext(index, row, rowContent);
  });

  const tableBody =
    rows.length > 0 ? (
      rows
    ) : (
      <tr>
        <td colSpan={colSpan} className="text-center py-2">
          This Subform Contains No Data
        </td>
      </tr>
    );

  return (
    <InputContext.Provider
      value={{
        inputContext: "subform",
      }}
    >
      <Table
        bordered
        className="mt-3 mb-2 table-regular-scale subform-table fs-7"
        id={displayItem.subformIdentifier}
      >
        <thead>
          <tr className="table-light">{tableHeader()}</tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
      <div className="d-flex justify-content-end mb-3">{buildNewButton()}</div>
    </InputContext.Provider>
  );
};

export default HorizontalSubformBody;
