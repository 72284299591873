import React, { useContext, useState } from "react";
import { FormContext, FieldDataContext } from "../../../contexts";

import LltInput from "./meddra/llt_input";
import SearchBox from "./meddra/search_box";
import PtInput from "./meddra/pt_input";
import SocInput from "./meddra/soc_input";

import { postRequest } from "../../../services/base_requests";
import ApiResponseInput from "./meddra/api_response_input";

const Meddra = () => {
  // Get our context
  const {
    readonly,
    meddraPostLocation,
    formValues,
    updateFormValue,
    updateFormValues,
    api_response,
  } = useContext(FormContext);
  const { buildInputName } = useContext(FieldDataContext);

  // Setup our state for use later
  const [responseData, setResponseData] = useState(JSON.parse(api_response));
  const [loading, setLoading] = useState(false);
  const [meddraError, setMeddraError] = useState(false);

  // Get some field data that will be used in multiple places
  const rawTermFieldData = formValues["raw_term"];
  const lltTermFieldData = formValues["llt_term"];
  const lltCodeFieldData = formValues["llt_code"];
  const ptTermFieldData = formValues["pt_term"];
  const ptCodeFieldData = formValues["pt_code"];
  const socTermFieldData = formValues["soc_term"];
  const socCodeFieldData = formValues["soc_code"];
  const unableToCodeFieldData = formValues["unable_to_code"];

  const searchLocked =
    responseData ||
    lltTermFieldData.value ||
    ptTermFieldData.value ||
    socTermFieldData.value;

  // Perform a basic MedDRA search and set state as appropriate
  const meddraSearch = () => {
    setLoading(true);
    postRequest({
      url: meddraPostLocation,
      body: { raw_term: rawTermFieldData.value },
    })
      .then((response) => {
        setLoading(false);
        return response.json();
      })
      .then((payload) => {
        console.log(payload);

        if (payload.status === "success") {
          const data = payload.data;
          setResponseData(data.llts);
          setMeddraError(null);

          if (data.llts.length == 0) {
            updateFormValues([
              ["llt_term", "Unable to Code"],
              ["llt_code", null],
              ["pt_term", null],
              ["pt_code", null],
              ["soc_term", null],
              ["soc_code", null],
              ["unable_to_code", "1"],
            ]);
          } else {
            // If our raw term exactly matches an llt name, preselect it
            const exactMatch = data.llts.find((llt) => {
              return (
                llt.llt_name.toLowerCase() ==
                rawTermFieldData.value.toLowerCase()
              );
            });

            if (exactMatch) {
              updateFormValues([
                ["llt_term", exactMatch.llt_name],
                ["llt_code", exactMatch.llt_code],
                ["pt_term", exactMatch.pt_name],
                ["pt_code", exactMatch.pt_code],
                ["soc_term", exactMatch.primary_soc.soc_name],
                ["soc_code", exactMatch.primary_soc.soc_code],
                ["unable_to_code", "0"],
              ]);
            }
          }
        } else {
          setResponseData(null);
          setMeddraError(payload.message);
        }
      });
  };

  return (
    <fieldset
      className={`prospect-fieldset rounded border border-1 pt-3 pb-2 mt-5 ${
        readonly ? "bg-light" : null
      }`}
    >
      <legend className="fs-5 px-2 fw-bold rounded border border-1 text-dark ">
        MedDRA coding
      </legend>

      <SearchBox
        {...{
          buildInputName,
          updateFormValue,
          updateFormValues,
          meddraSearch,
          searchLocked,
          meddraError,
          readonly,
          setResponseData,
          rawTermFieldData,
          lltTermFieldData,
          loading,
        }}
      ></SearchBox>
      <LltInput
        {...{
          responseData,
          lltTermFieldData,
          buildInputName,
          updateFormValues,
          lltCodeFieldData,
          unableToCodeFieldData,
        }}
      ></LltInput>
      <PtInput
        {...{
          responseData,
          ptTermFieldData,
          lltTermFieldData,
          ptCodeFieldData,
          buildInputName,
        }}
      ></PtInput>
      <SocInput
        {...{
          responseData,
          lltTermFieldData,
          socTermFieldData,
          socCodeFieldData,
          buildInputName,
          updateFormValues,
        }}
      ></SocInput>
      <ApiResponseInput {...{ responseData }}></ApiResponseInput>
    </fieldset>
  );
};

export default Meddra;
