import React from "react";
import { useContext } from "react";
import { InputContext } from "../../../contexts";
import { Form, Row, Col } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import RevisionInfo from "../RevisionInfo";

const TextInput = ({
  fieldData,
  onChangeHandler,
  errors,
  field,
  displayItem,
  readonly,
  placeholder,
  unit,
  stringFormat,
  labelSubtext,
  fieldPostText,
  inputName,
  fieldID,
  preventSubmitOnEnter = false,
}) => {
  // Get our inputContext
  const { inputContext } = useContext(InputContext);

  // Determine whether this field should be displayed normally or inline
  const displayInline =
    inputContext == "inline" ||
    inputContext == "table" ||
    inputContext == "subform";

  const fieldName = inputName;

  // Build our form label
  const formLabel = () => {
    if (displayItem.hideLabel) {
      return null;
    }

    let labelProps = {
      column: true,
      sm: displayInline ? "auto" : 5,
      className: "fw-bold lh-sm",
    };

    let upperLabel = <div>{displayItem.label}</div>;

    let lowerLabel = labelSubtext ? (
      <div className="fs-10pt fw-normal fst-italic text-muted">
        {labelSubtext}
      </div>
    ) : null;

    return (
      <Form.Label {...labelProps}>
        {upperLabel}
        {lowerLabel}
      </Form.Label>
    );
  };

  const smallInput =
    displayItem.widgetType == "textSmall" ||
    field.widgetType == "date" ||
    field.widgetType == "partialDate";

  const partialDateField = smallInput && field.type == "partialDate";

  // Helper function to parse unit strings and replace ^ with <sup> and _ with <sub>
  const parseUnit = (unit) => {
    if (!unit) return null;

    const unitElements = [];
    let i = 0;

    while (i < unit.length) {
      // handle superscript
      if (unit[i] === "^") {
        i++;
        let supNum = "";
        while (i < unit.length && /[0-9-]/.test(unit[i])) {
          // gather the digits after '^'
          supNum += unit[i];
          i++;
        }
        unitElements.push(<sup key={unitElements.length}>{supNum}</sup>);
        // handle subscript
      } else if (unit[i] === "_") {
        i++;
        let subNum = "";
        while (i < unit.length && /[0-9]/.test(unit[i])) {
          subNum += unit[i];
          i++;
        }
        unitElements.push(<sub key={unitElements.length}>{subNum}</sub>);
        // handle any other letters
      } else {
        unitElements.push(<span key={unitElements.length}>{unit[i]}</span>);
        i++;
      }
    }

    return unitElements;
  };

  // Build our form input
  const formControl = () => {
    let formControlClasses = ["px-2", "py-1"];

    let value;

    if (typeof fieldData.value === "number") {
      value = fieldData.value;
    } else {
      value = fieldData.value || (readonly ? "[left blank]" : "");
    }

    let displayValue =
      fieldData.value !== undefined && fieldData.value !== null
        ? fieldData.value.toString()
        : "";

    let unitElement = null;
    let stringFormatElement = null;

    if (readonly && unit && displayValue.trim() !== "") {
      unitElement = (
        <span className={`field-unit-${field.identifier} ms-1`}>
          {parseUnit(unit)}
        </span>
      );
    }

    if (readonly && stringFormat && displayValue.trim() !== "") {
      stringFormatElement = (
        <div className="text-muted fs-6 mt-1">{stringFormat}</div>
      );
    }

    let formControlProps = {
      type: "text",
      value,
      name: fieldName,
      onChange: (e) => {
        onChangeHandler(e);
      },
      isInvalid: errors.length > 0,
      placeholder: readonly ? null : placeholder,
    };

    // Most barcode scanners work by typing in the code and then simulating an enter keypress
    // We don't want this to submit the form, so can disable the default enter behaviour if specified
    const preventFormSubmission = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
    };
    if (preventSubmitOnEnter) {
      formControlProps["onKeyDown"] = preventFormSubmission;
    }

    if (displayInline) {
      formControlProps["sm"] = "auto";
    } else {
      formControlProps["sm"] = 8;
    }

    if (displayItem.widgetType == "textArea") {
      formControlProps["as"] = "textarea";
      formControlProps["rows"] = 5;
      formControlProps["cols"] = 20;
      formControlClasses.push("lh-sm");
      if (inputContext == "subform") {
        formControlProps["className"] = "w-auto lh-sm";
      }
    }

    if (smallInput) {
      formControlClasses.push("small-field");
    }

    if (readonly) {
      formControlProps["plaintext"] = true;
      formControlProps["readOnly"] = true;
      if (!fieldData.value && !(typeof fieldData.value === "number")) {
        formControlClasses.push("text-muted");
      }

      // If this is a readonly text area display as a div with the display-linebreak class.
      if (displayItem.widgetType == "textArea") {
        return (
          <div
            className={`display-linebreak ${
              readonly && !fieldData.value ? "text-muted" : null
            }`}
          >
            {fieldData.value || "[left blank]"}
          </div>
        );
      }
    }

    if (formControlClasses.length > 0) {
      formControlProps["className"] = formControlClasses.join(" ");
    }

    return <Form.Control {...formControlProps}></Form.Control>;
  };

  // Build our error messages
  const errorFeedback = (
    <Form.Control.Feedback type="invalid">
      {errors.join(", ")}
    </Form.Control.Feedback>
  );

  const tooltipData = (placeholer) => {
    switch (placeholder) {
      case "{dd}/mm/yyyy":
        return "Month and year are required, day is optional (but should be entered if known)";
      case "{dd}/{mm}/yyyy":
        return "Year is required, day and month are optional (but should be entered if known)";
      case "mm/yyyy":
        return "Month and year are required";
      case "{mm}/yyyy":
        return "Year is required, month is optional (but should be entered if known)";
      default:
        return null;
    }
  };

  const shouldAlignCenter = !(displayItem.widgetType == "textArea");

  if (displayInline) {
    return (
      <Col sm={"auto"}>
        <Form.Group
          controlId={fieldName}
          as={Row}
          className={`mb-3 ${shouldAlignCenter ? "align-items-center" : null}`}
          id={fieldID}
        >
          {formLabel()}
          <Col sm={"auto"}>
            {formControl()}
            <RevisionInfo fieldData={fieldData}></RevisionInfo>
            {errorFeedback}
            {stringFormat ? (
              <div
                className={`string-format-${field.identifier} mb-3 text-muted fs-6 mt-1`}
              >
                {stringFormat}
              </div>
            ) : null}
          </Col>
          {parseUnit(unit) ? (
            <Col sm={"auto"}>
              <div className={`field-unit-${field.identifier} fs-6`}>
                {parseUnit(unit)}
              </div>
            </Col>
          ) : null}
          {fieldPostText ? (
            <Col sm={"auto"}>
              <div className="fs-6 fst-italic text-muted">{fieldPostText}</div>
            </Col>
          ) : null}
        </Form.Group>
      </Col>
    );
  } else {
    return (
      <Form.Group
        controlId={fieldName}
        as={Row}
        className={`mb-3 ${shouldAlignCenter ? "align-items-center" : null}`}
        id={fieldID}
      >
        {formLabel()}
        <Col
          data-tooltip-id={partialDateField ? "my-tooltip" : null}
          data-tooltip-content={
            partialDateField && !readonly ? tooltipData(placeholder) : null
          }
          sm={smallInput ? "auto" : null}
        >
          <Tooltip id="my-tooltip" className="z-index-999" />
          {formControl()}
          <RevisionInfo fieldData={fieldData}></RevisionInfo>
          {errorFeedback}
          {stringFormat && (
            <div
              className={`string-format-${field.identifier} mb-3 text-muted fs-6 mt-1`}
            >
              {stringFormat}
            </div>
          )}
        </Col>
        {unit && (
          <Col sm={"auto"}>
            <div className={`field-unit-${field.identifier} fs-6 `}>
              {parseUnit(unit)}
            </div>
          </Col>
        )}
        {fieldPostText ? (
          <Col sm={"auto"}>
            <div className="fs-6 fst-italic text-muted">{fieldPostText}</div>
          </Col>
        ) : null}
      </Form.Group>
    );
  }
};

export default TextInput;
